<template>
  <div class="bg_main full_h">
    <header-formulir
      v-bind:date="$date()"
      v-bind:title="'Formulir Sanitarian-KIT'"
    />
    <div class="d-flex justify-center">
      <div style="margin-top: 50px; width: 85%" class="pb-5">
        <v-card
          class="rounded-max pa-3 d-flex align-center justify-center"
          min-height="500px"
          flat
        >
          <div style="width: 70%">
            <div class="d-flex justify-center mb-3">
              <img src="../../assets/img/isi-formulir.svg" alt="" />
            </div>
            <div>
              <p class="text-center ma-0 small_txt">
                Mohon Isi data sebelum tanggal 10 disetiap bulan. Apabila ingin
                menyimpan dan melakukan perbaikan data maka gunakan fungsi
                tombol simpan. Apabila data sudah final maka dapat memilih
                fungsi tombol kirim di bagian akhir formulir
              </p>
            </div>
            <div class="d-flex justify-center" style="margin-top: 30px">
              <v-btn
                dark
                v-if="!loading"
                color="#00b4cc"
                depressed
                @click="createForm($route.params.id)"
              >
                Mulai Isi Formulir
              </v-btn>
              <v-btn
                dark
                color="#00b4cc"
                v-if="loading"
                class="text-capitalize"
                depressed
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="#fff"
                ></v-progress-circular>
                Loading
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import HeaderFormulir from "./HeaderFormulir.vue";
export default {
  components: { HeaderFormulir },
  data() {
    return {
      date: null,
      loading: false,
    };
  },

  methods: {
    createForm(date) {
      let body = {
        date: date,
      };
      this.loading = true;
      this.$store
        .dispatch("formulir/createDataFormulirSanitarian", body)
        .then((data) => {
          console.log("return", data);
          this.$router.push(`/formulir/sanitarian-kit/${data.report_id}`);
          this.loading = false;
        })
        .catch((err) => {
          this.Swal("error", err.message);
          this.loading = false;
          console.log(err);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style>
</style>